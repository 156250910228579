<template>
  <b-row>
    <b-col class="mb-2" cols="12" md="4">
      <b-input-group>
        <b-input-group-prepend is-text>
          <feather-icon icon="SearchIcon" />
        </b-input-group-prepend>

        <b-form-input v-model="filters['full_name']" placeholder="Search" />
      </b-input-group>
    </b-col>

    <!-- Select Location  -->

    <b-col cols="12" md="3">
      <b-form-group>
        <v-select
          v-model="filters['job_role_id']"
          :options="jobRoleOption"
          :reduce="(jobRoleOption) => jobRoleOption.id"
          label="name"
          placeholder="All Roles"
          value="id"
        />
      </b-form-group>
      {{ filters["JobRole_id"] }}
    </b-col>
    <b-col cols="12" md="5">
      <b-form-group class="">
        <b-button
          class="btn-sm-block float-right ml-1 mr-1 mb-1 text-white"
          variant="dark"
          @click="callparentfunction()"
        >
          <span class="align-middle">Invite Staff</span>
        </b-button>
        <!-- <b-button
              class="btn-sm-block float-right ml-1 mr-1 mb-1 text-white"
              variant="primary"
            >
            <feather-icon class="mr-50" icon="SendIcon"/>
              <span class="align-middle">Send Group Message</span>
            </b-button> -->
      </b-form-group>
    </b-col>

    <b-col cols="12">
      <b-overlay :show="tableLoading" rounded="sm">
        <b-table
          ref="table"
          :current-page="currentPage"
          :fields="fields"
          :items="getUsers"
          :per-page="pagination.perPage"
          :sort-by.sync="sortBy"
          :sort-desc.sync="sortDesc"
          :sort-direction="sortDirection"
          class="mobile_table_css"
          hover
          responsive
          @row-clicked="navigateStaffProfile"
        >
          <!-- Name $ Avatar -->

          <template #cell(name)="data">
            <ul class="list-inline mb-0">
              <li class="list-inline-item">
                <b-avatar
                  :src="getUserImage(data.item.id, true)"
                  class="pull-up"
                />
              </li>
              <li class="list-inline-item">{{ data.item.name }}</li>
              <b-badge class="ml-1" style="padding: 8px" variant="light-info">
                <!--              <span class="text-dark font-weight-700"> {{data.item.role}} </span>-->
              </b-badge>
            </ul>
          </template>

          <template #cell(total_hours_worked)="data">
              <div class="list-inline-item"> {{ data.item.total_hours_worked.toFixed(0) }}</div>
          </template>

          <template v-slot:head(first_name)="data">
            <span v-b-tooltip.hover.top="'Sort'">{{ data.label }}</span>
          </template>


          <!-- Delete User Button -->
        </b-table>
      </b-overlay>
    </b-col>

    <b-col cols="12">
      <NoDataRow
        v-if="pagination.totalRows === 0"
        :messgae="'No Salaried Staff to display'"
      />
    </b-col>

    <!-- Pagination -->

    <b-col
      class="
        d-flex
        align-items-center
        justify-content-center justify-content-sm-start
      "
      cols="12"
      sm="6"
    >
      <span v-if="pagination.totalRows !== 0" class="text-muted"
        >Showing {{ pagination.from }} to {{ pagination.to }} of
        {{ pagination.totalRows }} entries</span
      >
    </b-col>

    <!-- Pagination -->

    <b-col
      class="
        d-flex
        align-items-center
        justify-content-center justify-content-sm-end
      "
      cols="12"
      sm="6"
    >
      <b-pagination
        v-model="currentPage"
        :per-page="pagination.perPage"
        :total-rows="pagination.totalRows"
        class="mb-0 mt-1 mt-sm-0"
        first-number
        last-number
        next-class="next-item"
        prev-class="prev-item"
      >
        <template #prev-text>
          <feather-icon icon="ChevronLeftIcon" size="18" />
        </template>

        <template #next-text>
          <feather-icon icon="ChevronRightIcon" size="18" />
        </template>
      </b-pagination>
    </b-col>
  </b-row>
</template>

<script>
import {
  BAlert,
  BAvatar,
  BBadge,
  BButton,
  BCol,
  BContainer,
  BDropdown,
  BDropdownItem,
  BForm,
  BFormCheckbox,
  BFormDatepicker,
  BFormGroup,
  BFormInput,
  BFormInvalidFeedback,
  BFormSelect,
  BInputGroup,
  BInputGroupAppend,
  BInputGroupPrepend,
  BListGroup,
  BListGroupItem,
  BModal,
  BOverlay,
  BPagination,
  BRow,
  BSidebar,
  BTable,
  VBModal,
  VBTooltip,
} from "bootstrap-vue";
import Ripple from "vue-ripple-directive";
// import settings from '@/apis/modules/settings'
import staffBank from "@/apis/modules/staffBank";
import ErrorMessages from "@/mixins/ErrorMessages";
import SuccessMessage from "@/mixins/SuccessMessage";
import MomentMixin from "@/mixins/MomentMixin";
import vSelect from "vue-select";
import VuePerfectScrollbar from "vue-perfect-scrollbar";
import draggable from "vuedraggable";
import { ValidationObserver, ValidationProvider } from "vee-validate";
import Filter from "@/mixins/FilterMixin";
import settings from "@/apis/modules/settings";
import NoDataRow from "@/views/staff-bank/Components/NoDataRow";
import { mapGetters } from "vuex";

export default {
  components: {
    BTable,
    BAvatar,
    BBadge,
    BRow,
    BCol,
    BFormGroup,
    BFormSelect,
    BPagination,
    BInputGroup,
    BFormInput,
    BInputGroupAppend,
    BButton,
    BDropdown,
    BDropdownItem,
    BOverlay,
    BInputGroupPrepend,
    BListGroup,
    BListGroupItem,
    vSelect,
    BModal,
    VBModal,
    BContainer,
    BAlert,
    BSidebar,
    BForm,
    BFormInvalidFeedback,
    BFormDatepicker,
    BFormCheckbox,
    VuePerfectScrollbar,
    draggable,
    ValidationProvider,
    ValidationObserver,
    NoDataRow,
  },
  directives: {
    Ripple,
    "b-tooltip": VBTooltip,
  },
  mixins: [ErrorMessages, SuccessMessage, MomentMixin, Filter],
  data() {
    return {
      filters: {},
      jobRoleOption: [],
      tableLoading: false,
      noDataTable: "",
      currentPage: 1,
      pagination: {
        perPage: "",
        pageOptions: "",
        totalRows: "",
        from: "",
        to: "",
      },
      sortBy: "",
      sortDesc: false,
      sortDirection: "asc",
      filter: null,
      filterOn: [],
      infoModal: {
        id: "info-modal",
        title: "",
        content: "",
      },
      fields: [
        {
          key: "first_name",
          label: "NAME",
          sortable: true,
        },
        {
          key: "role",
          label: "Role",
        },

        {
          key: "telephone",
          label: "telephone",
        },
        {
          key: "email",
          label: "EMAIL",
        },
        {
          key: "total_hours_worked",
          label: " total hours worked",
        },
        // {
        //   key: 'total_earned',
        //   label: 'total Earned',
        // },

        // {
        //   key: 'account_created',
        //   label: 'Date account created',
        //   sortable: true
        // },
        // {
        //   key: 'last_login',
        //   label: 'Last Login date',
        // },
      ],
      items: [],
    };
  },
  computed: {
    ...mapGetters(["currentUser"]),
  },
  methods: {
    callparentfunction() {
      this.$parent.$parent.$parent.sendInvitation();
    },
    async jobRoleList() {
      try {
        const practiceListResponse = await settings.getJobRoleList();
        this.jobRoleOption = practiceListResponse.data.data;
      } catch (e) {
        this.convertAndNotifyError(e);
      }
    },
    navigateStaffProfile(item) {
      this.$router.push(`/staff-bank/${item.id}`);
    },
    async getUsers() {
      try {
        this.tableLoading = true;
        if (this.sortBy === "") {
          this.sortDesc = true;
        }
        console.log(this.filterQuery);
        const Response = await staffBank.salariedStaffList(
            this.filterQuery, this.currentPage, 10, this.sortBy, this.sortDesc,'salaried-staff');

        const dataArray = Response.data.data.map((x) => ({
          id: x.id,
          first_name: x.first_name + " " + x.last_name,
          email: x.email,
          role: x.job_roles[0].name,
          telephone: x.phone_number ? x.phone_number : "N/A",
          account_created: this.momentFormat(
            x.email_verified_at,
            "ddd DD MM YYYY"
          ),
          last_login: this.momentFormat(new Date(), "ddd DD MM YYYY"),
          total_hours_worked: this.calculateHoursWorked(x.shifts),
          total_earned: "",
        }));
        this.noDataTable = Response.data.data.length;
        const paginationResponse = Response.data.meta;
        this.currentPage = paginationResponse.current_page;
        this.pagination.totalRows = paginationResponse.total;
        this.pagination.perPage = paginationResponse.per_page;
        this.pagination.from = paginationResponse.from;
        this.pagination.to = paginationResponse.to;
        this.tableLoading = false;
        return dataArray;
      } catch (error) {
        this.convertAndNotifyError(error);
        this.tableLoading = false;
      }
    },
    filterQueryUpdate() {
      // this.getUsers()
      this.$refs.table.refresh();
    },
    // confirmButtonColor() {
    //   this.$swal({
    //     title: 'Are you sure you want to delete Brooklyn Simmons?',
    //     text: "This action cannot be reverted.",
    //     icon: 'warning',
    //     showCancelButton: true,
    //     confirmButtonText: 'Yes, delete',
    //     cancelButtonText: 'No, go back',
    //     customClass: {
    //       confirmButton: 'btn btn-primary',
    //       cancelButton: 'btn btn-outline-primary ml-1',
    //     },
    //     buttonsStyling: false,
    //   }).then(result => {
    //     if (result.value) {
    //       this.$swal({
    //         icon: 'success',
    //         title: 'Deleted!',
    //         text: 'Your file has been deleted.',
    //
    //         customClass: {
    //           confirmButton: 'btn btn-success',
    //         },
    //       })
    //     } else if (result.dismiss === 'cancel') {
    //       this.$swal({
    //         title: 'Cancelled',
    //         text: 'Your imaginary file is safe :)',
    //
    //         icon: 'error',
    //         customClass: {
    //           confirmButton: 'btn btn-success',
    //         },
    //       })
    //     }
    //   })
    // },
    //
    // confirmButtonColor2() {
    //   this.$swal({
    //     title: 'Are you sure you want to assign admin role to Brooklyn Simmons?',
    //     text: "This person will have access to all practice information, and will be able to manage rota and staff bank.",
    //     icon: 'warning',
    //     showCancelButton: true,
    //     confirmButtonText: 'Yes, assign',
    //     cancelButtonText: 'No, go back',
    //     customClass: {
    //       confirmButton: 'btn btn-primary',
    //       cancelButton: 'btn btn-outline-primary ml-1',
    //     },
    //     buttonsStyling: false,
    //   }).then(result => {
    //     if (result.value) {
    //       this.$swal({
    //         icon: 'success',
    //         title: 'User Added Admin',
    //         text: 'User added admin role ',
    //
    //         customClass: {
    //           confirmButton: 'btn btn-success',
    //         },
    //       })
    //     } else if (result.dismiss === 'cancel') {
    //       this.$swal({
    //         title: 'Cancelled',
    //         text: 'Your imaginary file is safe :)',
    //         icon: 'error',
    //         customClass: {
    //           confirmButton: 'btn btn-success',
    //         },
    //       })
    //     }
    //   })
    // },
    dataRefrrsh() {
      this.$refs.table.refresh();
    },
  },

  mounted() {
    // Set the initial number of items
    this.jobRoleList();
  },
};
</script>
<style lang="scss">
@import "@core/scss/vue/libs/vue-select.scss";

div#dropdown-1 .btn-outline-danger {
  border: none !important;
}

button {
  height: 38px;
}
</style>
